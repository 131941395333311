export const imports = {
  'LICENSE.md': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "license" */ 'LICENSE.md'),
  'src/docs/home/index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-home-index" */ 'src/docs/home/index.mdx'),
  'src/docs/plugins/index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-plugins-index" */ 'src/docs/plugins/index.mdx'),
  'src/docs/themes/index.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-themes-index" */ 'src/docs/themes/index.mdx'),
  'src/docs/documentation/customizing/add-favicon-and-metadata.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-add-favicon-and-metadata" */ 'src/docs/documentation/customizing/add-favicon-and-metadata.mdx'),
  'src/docs/documentation/customizing/component-shadowing.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-component-shadowing" */ 'src/docs/documentation/customizing/component-shadowing.mdx'),
  'src/docs/documentation/customizing/creating-your-themes.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-creating-your-themes" */ 'src/docs/documentation/customizing/creating-your-themes.mdx'),
  'src/docs/documentation/customizing/customizing-webpack-config.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-customizing-webpack-config" */ 'src/docs/documentation/customizing/customizing-webpack-config.mdx'),
  'src/docs/documentation/customizing/gatsby-theme.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-gatsby-theme" */ 'src/docs/documentation/customizing/gatsby-theme.mdx'),
  'src/docs/documentation/customizing/powered-by-gatsby.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-customizing-powered-by-gatsby" */ 'src/docs/documentation/customizing/powered-by-gatsby.mdx'),
  'src/docs/documentation/general/built-in-components.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-built-in-components" */ 'src/docs/documentation/general/built-in-components.mdx'),
  'src/docs/documentation/general/deploying-your-docs.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-deploying-your-docs" */ 'src/docs/documentation/general/deploying-your-docs.mdx'),
  'src/docs/documentation/general/document-settings.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-document-settings" */ 'src/docs/documentation/general/document-settings.mdx'),
  'src/docs/documentation/general/getting-started.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-getting-started" */ 'src/docs/documentation/general/getting-started.mdx'),
  'src/docs/documentation/general/introduction.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-introduction" */ 'src/docs/documentation/general/introduction.mdx'),
  'src/docs/documentation/general/usage-in-monorepo.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-usage-in-monorepo" */ 'src/docs/documentation/general/usage-in-monorepo.mdx'),
  'src/docs/documentation/general/usage-with-css-preprocessors.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-usage-with-css-preprocessors" */ 'src/docs/documentation/general/usage-with-css-preprocessors.mdx'),
  'src/docs/documentation/general/usage-with-typescript.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-usage-with-typescript" */ 'src/docs/documentation/general/usage-with-typescript.mdx'),
  'src/docs/documentation/general/writing-mdx.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-general-writing-mdx" */ 'src/docs/documentation/general/writing-mdx.mdx'),
  'src/docs/documentation/references/components-api.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-components-api" */ 'src/docs/documentation/references/components-api.mdx'),
  'src/docs/documentation/references/creating-plugins.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-creating-plugins" */ 'src/docs/documentation/references/creating-plugins.mdx'),
  'src/docs/documentation/references/mdx-plugins.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-mdx-plugins" */ 'src/docs/documentation/references/mdx-plugins.mdx'),
  'src/docs/documentation/references/migration-guide.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-migration-guide" */ 'src/docs/documentation/references/migration-guide.mdx'),
  'src/docs/documentation/references/project-configuration.mdx': () =>
    import(/* webpackPrefetch: true, webpackChunkName: "src-docs-documentation-references-project-configuration" */ 'src/docs/documentation/references/project-configuration.mdx'),
}
